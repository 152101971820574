<template>
    <component
        :is="buttonComponent"
        @click.prevent.stop="sentEmail"
    >
        <z-icon
            v-if="!noIcon"
            icon="caret-circle-up"
            type="fas"
            class="text-yellow-500"
        />
        {{ text }}
    </component>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed } from 'vue';
import ZIcon from '@/components/ui/Icon.vue';
import { setLoading } from '@/utils/loading';
import api from '@/api';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import useUserStore from '@/user/store';

import PanelButton from '@/components/home/boxes/HomeBoxPanelButton.vue';

export default defineComponent({
    name: 'UpgradeRequestButton',
    components: { ZIcon, PanelButton },
    props: {
        feature: { type: String, required: true },
        text: { type: String, default: () => i18next.t('CONTACT_ACCOUNT_MANAGER_FOR_UPGRADE', 'Contact us') },
        buttonType: { type: String as () => 'panel'|'base', default: 'panel' },
        noIcon: { type: Boolean, default: false },
    },
    setup(props) {
        const { groupId } = toRefs(useUserStore().state);

        const buttonComponent = computed(() => {
            switch (props.buttonType) {
                case 'panel':
                    return 'panel-button';
                case 'base':
                default:
                    return 'z-button';
            }
        });

        const sentEmail = async () => {
            setLoading(true);
            const response = await api.requestUpgrade(groupId.value, props.feature);
            if (response === 'success') {
                toaster.success(i18next.t('DASHBOARD.UPGRADE_REQUEST_SUCCESS', 'Thank you! Your account manager will be in touch soon.'));
            } else {
                toaster.error(i18next.t('DASHBOARD.EMAIL_FAILURE', 'Something went wrong, please try again.'));
            }
            setLoading(false);
        };

        return { sentEmail, buttonComponent };
    }
});
</script>
